import { deepCopy } from '@/utils'

import { goodsList } from "@/api/O2OMall/goods/list";

import { getDicts } from '@/api/system/dict/data' //字段的一些接口
import { listlevel, listDecoration, listFreshlyBaked, freshlyBakedListRoom, freshlyBakedWorkerList, decorationListRoom } from '@/api/vip/base/level' //会员等级
import { listGoods } from '@/api/goods/goods' //商品列表
import { listCategory } from '@/api/goods/category' // 商品类别
import { listShopInfo } from '@/api/shop/base/shopInfo' // 门店资料
import { listShopCashier } from '@/api/shop/base/shopCashier' //收银员
import { listPayClass } from '@/api/shop/base/shopCashier' //收银方式
import { listShopmachine } from '@/api/shop/base/shopCashier' //收银机
import { guideManListAPI } from '@/api/shop/saleGuide/guideMan' //导购员
import { listBrand } from '@/api/goods/brand' //商品品牌
import { listStore } from '@/api/goods/store' //仓库接口
import { produceOrderList } from '@/api/produce/sets/produceOrder' //生产班次接口
import { listEmployee } from '@/api/system/employee' //员工
import { listVipCard } from '@/api/vip/base/vipCard'
import { listSupplier } from '@/api/goods/supplier' //供应商
import { listCustomer } from '@/api/goods/customer.js' //客户资料
import { produceGroupList } from '@/api/produce/sets/produceGroup' //生产组接口
import { queryProduceBillBomGoodsPage } from '@/api/produce/bom/service' //生产配方商品接口
import { listSimpleQuery } from '@/api/system/dept' //部门
import { billPostTypeList } from '@/api/system/dept' //记账类型
import { billTypeList } from '@/api/system/dept' //仓库单据类型
import { inOutStoreList } from '@/api/system/dept' //出入库方式
import { CouponList } from '@/api/marketing/coupon/case' //接口api
import { packageListAPI } from '@/api/marketing/coupon/package' //优惠券包
import { dataBillStatus } from '@/api/system/dept' // 单据状态
import { dataInOutStore } from '@/api/system/dept' // 调整方式
import { produceDeptList } from '@/api/produce/sets/produceDept' //生产工厂
import { priceCaseListAPI } from '@/api/system/goodsPrice/priceCase' //商品价格方案
import { produceStoreList } from '@/api/produce/sets/produceDeptStore' //生产仓库
import { listBankAccount } from '@/api/goods/finance'
import { listMainGoods } from '@/api/combinStrip/index' //选择组装商品
import { listPaymode } from '@/api/shop/base/paymode.js' //付款方式
import { listRpType } from '@/api/system/category/index' //收入 支出类别
import {
  listOptionShop //抖音门店列表(下拉选项)
} from '@/api/O2OThird/douyin/shop' //抖音
import {
  departmentList //生产部门
} from '@/api/shop/orderCenter/orderManage' //门店中台
import { payChannelListAPI } from '@/api/shop/base/payChannel' //支付通道
import { signListAPI } from '@/api/marketing/sms/sign' //短信签名
import { getOrderList } from '@/api/shop/bill/reserveOrder' //生产部门接口

import {
  goodsList as dianCanGoodsList
} from '@/api/O2OThird/orderMealWxa/goods'
import {
  queryDeliverLineList,
} from '@/api/system/partner/deliverLine' //配送路线
import {
  cakefreeselectGoodsList,
} from '@/api/O2OThird/cakeFreeSelectApp/goods'
import {
  franchiseGetAllStore
} from "@/api/joinClient/bill/order"; //加盟仓库
import { PromoList } from '@/api/marketing/coupon/case' //接口
import {
  vipBrandList
} from '@/api/vip/wxService/wxVipcard' //微信会员包
/**
 * 业务常用组件配置
 * @param {object} options
 * @param {string} options.key 下拉框配置的 key
 * @param {object} options.option 下拉框全局配置的额外配置 会覆盖 config 里所有内容
 * @param {boolean} options.getModel 适用于表单的 选择 true 则 将 filter 复制到 model, 默认 false
 * @returns {object} - 返回下拉框配置的对象
 */
export default ({ key, option = {}, getModel = false } = {}) => {
  let config = {}
  const defaultDict = {
    // 默认的请不要改的 可用通过传入 option 修改
    bill_status: { label: '单据状态', filter: 'billStatus' },
    sys_user_sex: { label: '性别', filter: 'sex' },
    vip_card_status: { label: '会员卡状态', filter: 'vipCardStatus' }
    ,
    coupon_mode: { label: '优惠券形式', filter: 'couponMode' },
    coupon_case_type: { label: '优惠券类型', filter: 'couponCaseType' },
    fetch_type: { label: '配送方式', filter: 'fetchType' },
    order_type: { label: '订单类型', filter: 'orderType' },
    order_status: { label: '订单状态', filter: 'orderStatus' },
    remind_type_id: { label: '消息类型', filter: 'couponCaseType' },
    wx_app_type: { label: '消息类型', filter: 'wxAppType' },
    coupon_take_out_type: { label: '发放方式', filter: 'takeOutType' },
    marketing_promo_type: { label: '方案类型', filter: 'promoType' },
    shop_hot_key: { label: '热键功能', filter: 'hotKeyNo' }
  }
  switch (key) {
    case defaultDict[key] ? key : 'is-a-undefined': // 接口字典的默认配置
      config = {
        type: 'local',
        model: '',
        ...defaultDict[key],
        option: {
          remote: getDicts,
          remoteBody: key,
          label: 'dictLabel',
          value: 'dictValue'
        }
      }
      break
    case 'vipBrandList': // 会员品牌
      config = {
        type: 'local',
        label: '会员品牌',
        model: '',
        filter: 'vipBrandId',
        option: {
          remote: vipBrandList,
          label: 'vipBrandName',
          value: 'vipBrandId',
          filterable: true,
          labels: [
            { title: '会员品牌编号', label: 'vipBrandNo' },
            { title: '会员名称名称', label: 'vipBrandName' }
          ]
        }
      }
      break
    case 'listPromo': // 促销方案
      config = {
        type: 'local',
        label: '促销方案',
        model: '',
        filter: 'billId',
        option: {
          remote: PromoList,
          label: 'promoName',
          value: 'billId',
          filterable: true,
          labels: [
            { title: '方案编号', label: 'billNo' },
            { title: '方案名称', label: 'promoName' }
          ]
        }
      }
      break
    case 'listFranchiseStore': // 弱加盟仓库
      config = {
        type: 'local',
        label: '加盟仓库',
        model: '',
        filter: 'storeId',
        option: {
          remote: franchiseGetAllStore,
          label: 'storeName',
          value: 'storeId',
          filterable: true,
          labels: [
            { title: '仓库编号', label: 'storeNo' },
            { title: '仓库名称', label: 'storeName' }
          ]
        }
      }
      break
    case 'listDeliverLine': // 配送路线
      config = {
        type: 'local',
        label: '配送路线',
        model: '',
        filter: 'lineId',
        option: {
          remote: queryDeliverLineList,
          label: 'lineName',
          value: 'lineId',
          filterable: true,
          // labels: [
          //   { title: '配送路线编号', label: 'lineNo' },
          //   { title: '配送路线名称', label: 'lineName' }
          // ]
        }
      }
      break
    case 'listDianCan': // 点餐商品
      config = {
        type: 'remote',
        label: '点餐商品',
        model: '',
        filter: 'goodsId',
        option: {
          remote: dianCanGoodsList,
          label: 'goodsName',
          value: 'goodsId',
          filterable: true,
        }
      }
      break
    case 'cakeDialog': // 蛋糕商品
      config = {
        type: 'remote',
        label: '蛋糕商品',
        model: '',
        filter: 'goodsId',
        option: {
          remote: cakefreeselectGoodsList,
          label: 'goodsName',
          value: 'goodsId',
          filterable: true
        }
      }
      break
    case 'listSignature': // 短信签名
      config = {
        type: 'remote',
        label: '短信签名',
        model: '',
        filter: 'signatureId',
        option: {
          remote: signListAPI,
          label: 'signatureContent',
          value: 'signatureId',
          filterable: true
        }
      }
      break
    case 'listPayChannel': // 支付通道分页
      config = {
        type: 'remote',
        label: '支付通道',
        model: '',
        filter: 'payChannelId',
        option: {
          remote: payChannelListAPI,
          label: 'payChannelName',
          value: 'payChannelId',
          filterable: true,
          labels: [
            { title: '支付通道编号', label: 'payChannelNo' },
            { title: '支付通道名称', label: 'payChannelName' }
          ]
        }
      }
      break
    case 'viplevel': // 分页接口
      config = {
        type: 'remote',
        label: '会员级别',
        model: '',
        filter: 'vipLevelId',
        option: {
          remote: listlevel,
          label: 'vipLevelName',
          value: 'vipLevelId',
          filterable: true,
          labels: [
            { title: '级别编号', label: 'vipLevelNo' },
            { title: '级别名称', label: 'vipLevelName' }
          ]
        }
      }
      break
    case 'decorationListRoom': // 门店
      config = {
        type: 'remote',
        label: '裱花间',
        model: '',
        filter: 'roomId',
        option: {
          remote: decorationListRoom,
          label: 'decorationRoomName',
          value: 'decorationRoomId',
          filterable: true,
          labels: [
            { title: '编号', label: 'decorationRoomNo' },
            { title: '名称', label: 'decorationRoomName' }
          ]
        }
      }
      break
    case 'freshlyBakedListRoom': // 现烤间
    case 'freshlyBakedListRoomDept':
      {
        const configKyes = {
          freshlyBakedListRoom: {
            selectConfig: { label: '现烤间', filter: 'roomId' }
          },
          freshlyBakedListRoomDept: {
            selectConfig: { label: '现烤间', filter: 'produceDeptId' }
          },
        }
        const { selectConfig, selectOption } = configKyes[key]
        config = {
          type: 'remote',
          model: '',
          ...selectConfig,
          option: {
            remote: freshlyBakedListRoom,
            remoteBody: { delFlag: 0 },
            label: 'freshlyBakedRoomName',
            value: 'freshlyBakedRoomId',
            filterable: true,
            labels: [
              { title: '编号', label: 'freshlyBakedRoomNo' },
              { title: '名称', label: 'freshlyBakedRoomName' }
            ],
            ...selectOption
          }
        }
      }
      break
    case 'freshlyBakedWorkerList': // 现烤师
      config = {
        type: 'remote',
        label: '现烤师',
        model: '',
        filter: 'workerId',
        option: {
          remote: freshlyBakedWorkerList,
          remoteBody: { delFlag: 0 },
          label: 'workerName',
          value: 'workerId',
          filterable: true,
          labels: [
            { title: '编号', label: 'workerNo' },
            { title: '名称', label: 'workerName' }
          ]
        }
      }
      break
    case 'listDecoration': // 分页接口
    case 'listDecorOrderStore': // 分页接口
    case 'listDecorInStore': // 调入仓库
    case 'listDecorOutStore': // 调出仓库
    case 'listDecorInventory': // 盘点仓库
    case 'listDecorCheckFillStore': // 盘盈仓库
    case 'listDecorCheckLoseStore': // 盘亏仓库
      {
        const configKyes = {
          listDecoration: {
            selectConfig: { label: '仓库', filter: 'storeId' }
          },
          listDecorOrderStore: {
            selectConfig: { label: '订货仓库', filter: 'storeId' }
          },
          listDecorInStore: {
            selectConfig: { label: '调入仓库', filter: 'inStoreId' }
          },
          listDecorOutStore: {
            selectConfig: { label: '调出仓库', filter: 'outStoreId' }
          },
          listDecorInventory: {
            selectConfig: { label: '盘点仓库', filter: 'storeId' }
          },
          listDecorCheckFillStore: {
            selectConfig: { label: '盘盈仓库', filter: 'inStoreId' }
          },
          listDecorCheckLoseStore: {
            selectConfig: { label: '盘亏仓库', filter: 'outStoreId' }
          },
        }
        const { selectConfig, selectOption } = configKyes[key]
        config = {
          type: 'remote',
          model: '',
          ...selectConfig,
          option: {
            remote: listDecoration,
            label: 'storeName',
            value: 'storeId',
            filterable: true,
            labels: [
              { title: '编号', label: 'storeNo' },
              { title: '名称', label: 'storeName' }
            ],
            ...selectOption
          }
        }
      }
      break
    case 'listFreshly': // 分页接口
    case 'listFreshlyOrderStore': // 分页接口
    case 'listFreshlyInStore': // 调入仓库
    case 'listFreshlyOutStore': // 调出仓库
    case 'listFreshlyInventory': // 盘点仓库
    case 'listFreshlyCheckFillStore': // 盘盈仓库
    case 'listFreshlyCheckLoseStore': // 盘亏仓库
      {
        const configKyes = {
          listFreshly: {
            selectConfig: { label: '仓库', filter: 'storeId' }
          },
          listFreshlyOrderStore: {
            selectConfig: { label: '订货仓库', filter: 'storeId' }
          },
          listFreshlyInStore: {
            selectConfig: { label: '调入仓库', filter: 'inStoreId' }
          },
          listFreshlyOutStore: {
            selectConfig: { label: '调出仓库', filter: 'outStoreId' }
          },
          listFreshlyInventory: {
            selectConfig: { label: '盘点仓库', filter: 'storeId' }
          },
          listFreshlyCheckFillStore: {
            selectConfig: { label: '盘盈仓库', filter: 'inStoreId' }
          },
          listFreshlyCheckLoseStore: {
            selectConfig: { label: '盘亏仓库', filter: 'outStoreId' }
          },
        }
        const { selectConfig, selectOption } = configKyes[key]
        config = {
          type: 'remote',
          model: '',
          ...selectConfig,
          option: {
            remote: listFreshlyBaked,
            label: 'storeName',
            value: 'storeId',
            filterable: true,
            labels: [
              { title: '编号', label: 'storeNo' },
              { title: '名称', label: 'storeName' }
            ],
            ...selectOption
          }
        }
      }
      break
    case 'listPriceCase': // 分页接口
      config = {
        type: 'remote',
        label: '商品价格方案',
        model: '',
        filter: 'priceCaseId',
        option: {
          remote: priceCaseListAPI,
          label: 'priceCaseName',
          value: 'priceCaseId',
          filterable: true,
          labels: [
            { title: '编号', label: 'priceCaseNo' },
            { title: '名称', label: 'priceCaseName' }
          ]
        }
      }
      break
    case 'o2oGoods': // 商品资料分页
      config = {
        type: 'remote',
        label: '商品',
        model: '',
        filter: 'goodsId',
        option: {
          remote: goodsList,
          remoteBody: { status: 0, goodsType: 1 },
          label: 'goodsNo',
          value: 'goodsId',
          filterable: true,
          labels: [
            { title: '商品编码', label: 'goodsNo' },
            { title: '商品名称', label: 'goodsName' },
            { title: '条码', label: 'barcode' }
          ]
        }
      }
      break
    case 'listGoods': // 商品资料分页
      config = {
        type: 'remote',
        label: '商品',
        model: '',
        filter: 'goodsId',
        option: {
          remote: listGoods,
          remoteBody: { goodsTypes: [0, 1, 2, 3, 4] },
          label: 'goodsName',
          value: 'goodsId',
          filterable: true,
          labels: [
            { title: '商品编码', label: 'goodsNo' },
            { title: '商品名称', label: 'goodsName' },
            { title: '条码', label: 'barcode' }
          ]
        }
      }
      break
    case 'listBomGoods': // 生产配方商品资料分页
      config = {
        type: 'remote',
        label: '商品',
        model: '',
        filter: 'goodsId',
        width: 200,
        option: {
          width: 200,
          remote: queryProduceBillBomGoodsPage,
          remoteBody: { goodsTypes: [1, 2] },
          label: 'goodsName',
          value: 'goodsId',
          filterable: true,
          labels: [
            { title: '商品编码', label: 'goodsNo' },
            { title: '商品名称', label: 'goodsName' },
            { title: '条码', label: 'barcode' }
          ]
        }
      }
      break
    case 'listCategory': // 商品分类分页
      config = {
        type: 'remote',
        label: '商品类别',
        model: '', //
        filter: 'categoryId',
        option: {
          remote: listCategory,
          label: 'categoryName',
          value: 'categoryId',
          filterable: true,
          labels: [
            { title: '编号', label: 'categoryNo' },
            { title: '名称', label: 'categoryName' }
          ]
        }
      }
      break
    case 'listCashier': // 门店收银员分页
      config = {
        type: 'remote',
        label: '收银员',
        model: '', //
        filter: 'cashierId',
        option: {
          remote: listShopCashier,
          label: 'cashierName',
          value: 'cashierId',
          filterable: true,
          labels: [
            { title: '编号', label: 'cashierNo' },
            { title: '名称', label: 'cashierName' }
          ]
        }
      }
      break
    case 'listPayClass': // 门店收银方式分页
      config = {
        type: 'remote',
        label: '收银方式',
        model: '', //
        filter: 'payModeId',
        option: {
          remote: listPayClass,
          label: 'payModeName',
          value: 'payModeId',
          filterable: true,
          labels: [
            { title: '编号', label: 'payModeNo' },
            { title: '名称', label: 'payModeName' }
          ]
        }
      }
      break
    case 'listMachine': // 门店收银机分页
      config = {
        type: 'remote',
        label: '收银机',
        model: '', //
        filter: 'machineId',
        option: {
          remote: listShopmachine,
          label: 'machineName',
          value: 'machineId',
          filterable: true,
          labels: [
            { title: '编号', label: 'machineNo' },
            { title: '名称', label: 'machineName' }
          ]
        }
      }
      break
    case 'listGuideMan': // 导购员分页
      config = {
        type: 'remote',
        label: '导购员',
        model: '', //
        filter: 'guideManId',
        option: {
          remote: guideManListAPI,
          label: 'guideManName',
          value: 'guideManId',
          filterable: true,
          labels: [
            { title: '编号', label: 'guideManNo' },
            { title: '名称', label: 'guideManName' }
          ]
        }
      }
      break
    case 'listBrand': // 品牌分页
      config = {
        type: 'remote',
        label: '品牌',
        model: '', //
        filter: 'brandId',
        option: {
          remote: listBrand,
          label: 'brandName',
          value: 'brandId',
          filterable: true,
          labels: [
            { title: '编号', label: 'brandNo' },
            { title: '名称', label: 'brandName' }
          ]
        }
      }
      break
    case 'listSupplier': // 供应商分页
      config = {
        type: 'remote',
        label: '供应商',
        model: '', //
        filter: 'partnerId',
        option: {
          remote: listSupplier,
          label: 'partnerName',
          value: 'partnerId',
          filterable: true,
          labels: [
            { title: '编号', label: 'partnerNo' },
            { title: '名称', label: 'partnerName' }
          ]
        }
      }
      break
    case 'listCustomer': // 客户分页
      config = {
        type: 'remote',
        label: '客户',
        model: '', //
        filter: 'partnerId',
        option: {
          remote: listCustomer,
          label: 'partnerName',
          value: 'partnerId',
          filterable: true,
          labels: [
            { title: '编号', label: 'partnerNo' },
            { title: '名称', label: 'partnerName' }
          ]
        }
      }
      break
    case 'listStore': // 仓库分页
    case 'listInStoreWarehousing': // 入库仓库
    case 'listInStore': // 调入仓库
    case 'listCheckFillStore': // 盘盈仓库
    case 'listOutStore': // 调出仓库
    case 'listCheckLoseStore': // 盘亏仓库
    case 'listInventory': // 盘点仓库
    case 'listStoreName': // 仓库名称
    case 'listSendStore': // 发货仓库
    case 'listDeliveryStore': // 收货仓库
    case 'decorationRoomStore': // 裱花仓库
    case 'freshlyBakedRoomStore': // 现烤仓库
    case 'listGoOutStore': // 领出仓库
    case 'listOrderStore': // 领出仓库
      {
        const configKyes = {
          listStore: {
            selectConfig: { label: '仓库', filter: 'storeId' }
          },
          listInStore: {
            selectConfig: { label: '调入仓库', filter: 'inStoreId' }
          },
          listCheckFillStore: {
            selectConfig: { label: '盘盈仓库', filter: 'inStoreId' }
          },
          listInStoreWarehousing: {
            selectConfig: { label: '入库仓库', filter: 'inStoreId' }
          },
          listOutStore: {
            selectConfig: { label: '调出仓库', filter: 'outStoreId' }
          },
          listCheckLoseStore: {
            selectConfig: { label: '盘亏仓库', filter: 'outStoreId' }
          },
          listGoOutStore: {
            selectConfig: { label: '领出仓库', filter: 'outStoreId' }
          },
          listInventory: {
            selectConfig: { label: '盘点仓库', filter: 'storeId' }
          },
          listStoreName: {
            selectConfig: { label: '仓库名称', filter: 'storeId' }
          },
          listSendStore: {
            selectConfig: { label: '发货仓库', filter: 'outStoreId' }
          },
          listDeliveryStore: {
            selectConfig: { label: '收货仓库', filter: 'inStoreId' }
          },
          listOrderStore: {
            selectConfig: { label: '订货仓库', filter: 'orderStoreId' }
          },
          decorationRoomStore: {
            selectConfig: {
              label: '裱花仓库',
              filter: 'decorationRoomStoreId'
            }
          },
          freshlyBakedRoomStore: {
            selectConfig: {
              label: '现烤仓库',
              filter: 'freshlyBakedRoomStoreId'
            }
          },
        }
        const { selectConfig, selectOption } = configKyes[key]
        config = {
          type: 'remote',
          model: '',
          ...selectConfig,
          option: {
            remote: listStore,
            label: 'storeName',
            value: 'storeId',
            filterable: true,
            labels: [
              { title: '编号', label: 'storeNo' },
              { title: '名称', label: 'storeName' }
            ],
            ...selectOption
          }
        }
      }
      break
    case 'listProduceOrder': // 生产班次分页
      config = {
        type: 'remote',
        label: '生产班次',
        model: '', //
        filter: 'produceOrderId',
        option: {
          remote: produceOrderList,
          label: 'produceOrderName',
          value: 'produceOrderId',
          filterable: true,
          labels: [
            { title: '编号', label: 'produceOrderNo' },
            { title: '名称', label: 'produceOrderName' }
          ]
        }
      }
      break
    case 'listReserveOrder': // 生产部门
      config = {
        type: 'remote',
        label: '生产部门',
        model: '', //
        filter: 'listReserveOrder',
        option: {
          remote: getOrderList,
          label: 'decorationRoomName',
          value: 'decorationRoomId',
          filterable: true,
          labels: [
            { title: '编号', label: 'decorationRoomId' },
            { title: '名称', label: 'decorationRoomName' }
          ]
        }
      }
      break
    case 'listProduceGroup': // 生产组分页
      config = {
        type: 'remote',
        label: '生产组',
        model: '', //
        filter: 'produceGroupId',
        option: {
          remote: produceGroupList,
          label: 'produceGroupName',
          value: 'produceGroupId',
          filterable: true,
          labels: [
            { title: '编号', label: 'produceGroupNo' },
            { title: '名称', label: 'produceGroupName' }
          ]
        }
      }
      break
    case 'listEmployee': // 员工分页
    case 'listPurEmployee': // 采购员
    case 'listSaleEmployee': // 业务员
    case 'listPurCreateEmployee': // 创建人
    case 'listPurAuditEmployee': //审核人
    case 'listCheckEmployee': //盘点人
    case 'listCheckAccountEmployee': //对账人
    case 'listUpdateEmployee': //修改人
      {
        const configKyes = {
          listEmployee: {
            selectConfig: { label: '员工', filter: 'employeeId' }
          },
          listPurEmployee: {
            selectConfig: { label: '采购员', filter: 'purEmployeeId' }
          },
          listSaleEmployee: {
            selectConfig: { label: '业务员', filter: 'saleEmployeeId' }
          },
          listPurCreateEmployee: {
            selectConfig: { label: '创建人', filter: 'createEmployeeId' }
          },
          listPurAuditEmployee: {
            selectConfig: { label: '审核人', filter: 'auditEmployeeId' }
          },
          listCheckEmployee: {
            selectConfig: { label: '盘点人员', filter: 'checkEmployeeId' }
          },
          listCheckAccountEmployee: {
            selectConfig: { label: '对账人', filter: 'checkAccountEmployeeId' }
          },
          listUpdateEmployee: {
            selectConfig: { label: '修改人', filter: 'updateEmployeeId' }
          }
        }
        const { selectConfig, selectOption } = configKyes[key]
        config = {
          type: 'remote',
          model: '', //
          ...selectConfig,
          option: {
            remote: listEmployee,
            label: 'employeeName',
            value: 'employeeId',
            filterable: true,
            labels: [
              { title: '编号', label: 'employeeNo' },
              { title: '名称', label: 'employeeName' }
            ],
            ...selectOption
          }
        }
      }
      break
    case 'listCoupon':
      config = {
        type: 'remote',
        label: '优惠券',
        model: '', //
        filter: 'couponCaseId',
        option: {
          remote: CouponList,
          label: 'couponCaseName',
          value: 'couponCaseId',
          filterable: true,
          labels: [
            { title: '编号', label: 'couponCaseNo' },
            { title: '名称', label: 'couponCaseName' }
          ]
        }
      }
      break
    case 'listCouponPackage':
      config = {
        type: 'remote',
        label: '券包',
        model: '', //
        filter: 'packageId',
        option: {
          remote: packageListAPI,
          label: 'packageName',
          value: 'packageId',
          filterable: true,
          labels: [{ title: '名称', label: 'packageName' }]
        }
      }
      break
    case 'vip': // 分页接口
      config = {
        type: 'remote',
        label: '会员',
        model: '',
        filter: 'vipId',
        option: {
          remote: listVipCard,
          label: 'vipName',
          value: 'vipId',
          filterable: true,
          labels: [
            { title: '会员编号', label: 'vipNo' },
            { title: '会员名称', label: 'vipName' }
          ]
        }
      }
      break
    case 'listDepartment': // 生产部门分页接口
      config = {
        type: 'local',
        label: '生产部门',
        model: '', //
        filter: 'decorationRoomId',
        option: {
          remote: departmentList,
          label: 'decorationRoomName',
          value: 'decorationRoomId',
          filterable: true,
          labels: [
            { title: '部门编号', label: 'decorationRoomNo' },
            { title: '部门名称', label: 'decorationRoomName' }
          ]
        }
      }
      break
    case 'shopInfo': // 分页接口
    case 'listShop': // 分页接口
      config = {
        type: 'remote',
        label: '门店',
        model: '', //
        filter: 'shopId',
        option: {
          remote: listShopInfo,
          label: 'shopName',
          value: 'shopId',
          filterable: true,
          labels: [
            { title: '门店编号', label: 'shopNo' },
            { title: '门店名称', label: 'shopName' }
          ]
        }
      }
      break
    case 'listDouYinShop': // 分页接口
      config = {
        type: 'remote',
        label: '门店',
        model: '', //
        filter: 'shopId',
        option: {
          remote: listOptionShop,
          label: 'shopName',
          value: 'shopId',
          filterable: true,
          labels: [
            { title: '门店编号', label: 'shopNo' },
            { title: '门店名称', label: 'shopName' }
          ]
        }
      }
      break
    case 'listVerification':
      config = {
        type: 'remote',
        label: '核销门店',
        model: '',
        filter: 'usedCheckShopId',
        option: {
          remote: listShopInfo,
          label: 'shopName',
          value: 'shopId',
          filterable: true,
          labels: [
            { title: '门店编号', label: 'shopNo' },
            { title: '门店名称', label: 'shopName' }
          ]
        }
      }
      break
    case 'billTypes': // 单据类型
      config = {
        type: 'local',
        label: '单据类型',
        model: '', //
        filter: 'billType',
        option: {
          data: [
            { label: '销售出库', value: '120102' },
            { label: '销售退货', value: '120103' }
          ],
          label: 'label',
          value: 'value',
          filterable: true
        }
      }
      break
    case 'listSimpleQuery': // 部门
      config = {
        type: 'local',
        label: '部门',
        model: '', //
        filter: 'deptId',
        option: {
          remote: listSimpleQuery,
          dataKey: 'data',
          label: 'deptName',
          value: 'deptId',
          filterable: true,
          labels: [
            { title: '编号', label: 'deptNo' },
            { title: '名称', label: 'deptName' }
          ]
        }
      }
      break
    case 'listProduceDept': // 生产工厂
      config = {
        type: 'remote',
        label: '生产工厂',
        model: '', //
        filter: 'produceDeptId',
        option: {
          remote: produceDeptList,
          label: 'produceDeptName',
          value: 'produceDeptId',
          filterable: true,
          labels: [
            { title: '编号', label: 'produceDeptNo' },
            { title: '名称', label: 'produceDeptName' }
          ]
        }
      }
      break
    case 'listProduceStore': // 生产仓库
      config = {
        type: 'remote',
        label: '生产仓库',
        model: '', //
        filter: 'storeId',
        option: {
          remote: produceStoreList,
          label: 'produceStoreName',
          value: 'storeId',
          filterable: true,
          labels: [
            { title: '编号', label: 'produceStoreNo' },
            { title: '名称', label: 'produceStoreName' },
            { title: '属性', label: 'storeTypeName' }
          ]
        }
      }
      break
    case 'listBillPostTypes': // 记账类型
      config = {
        type: 'local',
        label: '记账类型',
        model: '', //
        filter: 'billPostType',
        option: {
          remote: billPostTypeList,
          dataKey: 'data',
          label: 'dictLabel',
          value: 'dictValue',
          filterable: true,
          labels: [
            { title: '编号', label: 'dictValue' },
            { title: '名称', label: 'dictLabel' }
          ]
        }
      }
      break
    case 'billTypeList': // 单据类型
      config = {
        type: 'local',
        label: '单据类型',
        model: '', //
        filter: 'billType',
        option: {
          remote: billTypeList,
          dataKey: 'data',
          label: 'dictLabel',
          value: 'dictValue',
          filterable: true,
          labels: [
            { title: '编号', label: 'dictValue' },
            { title: '名称', label: 'dictLabel' }
          ]
        }
      }
      break
    case 'inOutStoreList': // 出入库方式
      config = {
        type: 'local',
        label: '出入库方式',
        model: '', //
        filter: 'inOutStore',
        option: {
          remote: inOutStoreList,
          dataKey: 'data',
          label: 'dictLabel',
          value: 'dictValue',
          filterable: true,
          labels: [
            { title: '编号', label: 'dictValue' },
            { title: '名称', label: 'dictLabel' }
          ]
        }
      }
      break
    case 'dataBillStatus': // 仓库单据状态
      config = {
        type: 'local',
        label: '单据状态',
        model: '', //
        filter: 'includeBillStatu',
        option: {
          remote: dataBillStatus,
          dataKey: 'data',
          label: 'dictLabel',
          value: 'dictValue',
          filterable: true
        }
      }
      break
    case 'dataInOutStore': // 调整方式
      config = {
        type: 'local',
        label: '调整方式',
        model: '', //
        filter: 'billPostType',
        option: {
          remote: dataInOutStore,
          dataKey: 'data',
          label: 'dictLabel',
          value: 'dictValue',
          filterable: true
        }
      }
      break
    // 营销
    case 'executionStatus': // 执行状态
      config = {
        type: 'local',
        label: '执行状态',
        model: '', //
        filter: 'executionStatus',
        option: {
          data: [
            { label: '未开始', value: '1' },
            { label: '进行中', value: '2' },
            { label: '已失效', value: '3' }
          ],
          label: 'label',
          value: 'value',
          filterable: true
        }
      }
      break
    case 'couponStatuse': // 优惠券状态
      config = {
        type: 'local',
        label: '优惠券状态',
        model: '', //
        filter: 'couponStatuse',
        option: {
          data: [
            { label: '已领出', value: '1' },
            { label: '已售出', value: '2' },
            { label: '售出退回', value: '3' },
            { label: '已使用', value: '4' },
            { label: '代理商领取', value: '5' },
            { label: '已过期', value: '100' },
            { label: '已作废', value: '101' }
          ],
          label: 'label',
          value: 'value',
          filterable: true
        }
      }
      break
    case 'isStopState': // 是否启用
    case 'isStopNum': // 是否启用
      const configKyes = {
        isStopState: {
          selectConfig: [
            { label: '启用', value: 'false' },
            { label: '禁用', value: 'true' }
          ]
        },
        isStopNum: {
          selectConfig: [
            { label: '启用', value: '1' },
            { label: '禁用', value: '0' }
          ]
        }
      }
      const { selectConfig, selectOption } = configKyes[key]
      config = {
        type: 'local',
        label: '启用状态',
        model: '',
        filter: 'isStop',
        option: {
          data: [...selectConfig],
          label: 'label',
          value: 'value',
          filterable: true
        }
      }
      break
    case 'refundAuditStatus': // 退款审核状态
      config = {
        type: 'local',
        label: '退款审核状态',
        model: '', //
        filter: 'refundAuditStatu',
        option: {
          data: [
            // 0 已退款未审核 1 已退款部分审核 2 已退款已审核
            { label: '已退款未审核', value: '0' },
            { label: '已退款部分审核', value: '1' },
            { label: '已退款已审核', value: '2' }
          ],
          label: 'label',
          value: 'value',
          filterable: true
        }
      }
      break
    case 'account': // 账户
      config = {
        type: 'remote',
        label: '账户',
        model: '',
        filter: 'bankAccountId',
        option: {
          remote: listBankAccount,
          label: 'bankAccountName',
          value: 'bankAccountId',
          filterable: true,
          labels: [
            { title: '账户编号', label: 'bankAccountNo' },
            { title: '账户名称', label: 'bankAccountName' }
          ]
        }
      }
      break
    case 'mainGoods': // 主商品
      config = {
        type: 'remote',
        label: '账户',
        model: '',
        filter: 'relateGoodsNos',
        option: {
          remote: listMainGoods,
          label: 'mainGoodsName',
          value: 'mainGoodsId',
          filterable: true,
          labels: [
            { title: '商品编码', label: 'mainGoodsNo' },
            { title: '账户名称', label: 'mainGoodsName' }
          ]
        }
      }
      break
    case 'paymode': // 账户
      config = {
        type: 'remote',
        label: '收款账号',
        model: '',
        filter: 'payModeId',
        option: {
          remote: listPaymode,
          label: 'payModeName',
          value: 'payModeId',
          filterable: true,
          labels: [
            { title: '账户编号', label: 'payModeNo' },
            { title: '账户名称', label: 'payModeName' }
          ]
        }
      }
      break
    case 'includeSettleStatu': // 核销状态
      config = {
        type: 'local',
        label: '核销状态',
        model: '',
        filter: 'includeSettleStatu',
        option: {
          data: [
            { label: '未核销', value: '0' },
            { label: '部分核销', value: '1' },
            { label: '已核销', value: '2' }
          ],
          label: 'label',
          value: 'value',
          filterable: true
        }
      }
      break
    case 'billCustomType': // 业务类型
      config = {
        type: 'local',
        label: '业务类型',
        model: '', //
        filter: 'billCustomType',
        option: {
          data: [
            { label: '同价调拨', value: '1' },
            { label: '异价调拨', value: '2' }
          ],
          label: 'label',
          value: 'value',
          filterable: true
        }
      }
      break
    case 'includeBillStatus': // 执行状态
      config = {
        type: 'local',
        label: '单据状态',
        model: '', //
        filter: 'includeBillStatu',
        option: {
          data: [
            { label: '未审核', value: '0' },
            { label: '已审核', value: '2' },
            { label: '已删除', value: '3' },
            { label: '已关闭', value: '4' }
          ],
          label: 'label',
          value: 'value',
          filterable: true
        }
      }
      break
    // 营销
    case 'isStatus': // 瓜分方式
      config = {
        type: 'local',
        label: '瓜分方式',
        model: '', //
        filter: 'isStatus',
        option: {
          data: [
            { label: '好友均分', value: '1' },
            { label: '拼手气', value: '2' }
          ],
          label: 'label',
          value: 'value',
          filterable: true
        }
      }
      break
    // 营销使用状态
    case 'isCouponStatus': // 使用状态
      config = {
        type: 'local',
        label: '使用状态',
        model: '', //
        filter: 'couponStatus',
        option: {
          data: [
            { label: '未使用', value: '0' },
            { label: '已使用', value: '1' }
          ],
          label: 'label',
          value: 'value',
          filterable: true
        }
      }
      break
    // 对账状态
    case 'checkAccountStatus': // 对账状态
      config = {
        type: 'local',
        label: '对账状态',
        model: '', //
        filter: 'checkAccountStatus',
        option: {
          data: [
            { label: '未对账', value: '0' },
            { label: '已对账', value: '1' }
          ],
          label: 'label',
          value: 'value',
          filterable: true
        }
      }
      break
    case 'rpType': // 收入类别
    case 'expenditure': // 支出类别
      {
        const configKyes = {
          rpType: {
            selectConfig: { label: '收入类别', filter: 'rpTypeNo' },
            selectOption: {
              labels: [
                { title: '收入编号', label: 'rpTypeNo' },
                { title: '收入名称', label: 'rpTypeName' }
              ]
            }
          },
          expenditure: {
            selectConfig: { label: '支出类别', filter: 'rpTypeNo' },
            selectOption: {
              labels: [
                { title: '支出编号', label: 'rpTypeNo' },
                { title: '支出名称', label: 'rpTypeName' }
              ]
            }
          }
        }
        const { selectConfig, selectOption } = configKyes[key]
        config = {
          type: 'remote',
          // label: '收入类别',
          model: '',
          filter: 'rpTypeNo',
          ...selectConfig,
          option: {
            remote: listRpType,
            label: 'rpTypeName',
            value: 'rpTypeId',
            filterable: true,
            ...selectOption
          }
        }
      }
      break
    // 营销
    case 'freshlyBakedProduceStatus': // 完成状态
      config = {
        type: 'local',
        label: '完成状态',
        model: '', //
        filter: 'freshlyBakedProduceStatus',
        option: {
          data: [
            { label: '待生产', value: '1' },
            { label: '生产完成', value: '2' },
            { label: '已调出', value: '3' }
          ],
          label: 'label',
          value: 'value',
          filterable: true
        }
      }
      break
    default:
      break
  }
  // console.log(deepCopy(option))
  const selectOpt = { ...deepCopy(config), ...deepCopy(option) }
  const op = {
    filterable: true,
    ...selectOpt,
    ...(getModel
      ? {
        model:
          typeof getModel === 'boolean' && getModel
            ? selectOpt.filter
            : getModel
      }
      : {}),
    option: {
      ...deepCopy(config)?.option,
      ...deepCopy(option)?.option
    }
  }
  if (op.option?.multiple && !option?.filter) {
    // 多选且没自定义 查询字段则 filter 加 s
    op.filter = `${op.filter}s`
  }
  if (op.option?.multiple && !op.option?.remoteEcho && op.type === 'remote') {
    // 多选且没自定义 查询字段则 remoteEcho 加 s
    op.option.remoteEcho = `${op.option.value || op.option.valueKey}s`
  }

  return op
}
