const product={
    state:{
        // 订单产品详情
        ProductDetail:{}
    },
    mutations:{
        setProductDetail(state,obj){
            state.ProductDetail=obj
        }
    },
    actions:{

    }
}
export default product