import Vue from "vue";
import Cookies from "js-cookie";
import store from "@/store";

import Element from "element-ui";
import "./assets/styles/element-variables.scss";

import echarts from "echarts";

import * as socket from "@/utils/webSocket";
Vue.prototype.$SOCKET = socket;
import "@/assets/iconfont/iconfont.css"; // 导入iconfont样式
import "@/assets/styles/index.scss"; // global css
import "@/assets/styles/baling.scss"; // baling css
import App from "./App";
import useStore from "./store";
import router from "./router";
import directive from "./directive"; // directive
import plugins from "./plugins"; // plugins
import { download } from "@/utils/request";
import "@/utils/VXETable"; //虚拟滚动
import selectConfig from "@/utils/select-config.js";
import dialogConfig from "@/utils/dialog-config.js";
import "./assets/icons"; // icon
import "./permission"; // permission control
import { getDicts } from "@/api/system/dict/data";
import { getConfigKey } from "@/api/system/config";
import {
  parseTime,
  resetForm,
  addDateRange,
  selectDictLabel,
  selectDictLabels,
  handleTree,
} from "@/utils/baling";
//公共方法
import publicMethods from "@/utils/publicMethods";
// 分页组件
import Pagination from "@/components/Pagination";
// 自定义表格工具组件
import RightToolbar from "@/components/RightToolbar";
// 富文本组件
import Editor from "@/components/Editor";
// 文件上传组件
import FileUpload from "@/components/FileUpload";
// 图片上传组件
import ImageUpload from "@/components/ImageUpload";
// 图片预览组件
import ImagePreview from "@/components/ImagePreview";
// 字典标签组件
import DictTag from "@/components/DictTag";
// 头部标签组件
import VueMeta from "vue-meta";
// 字典数据组件
import DictData from "@/components/DictData";
// 业务字典数据组件
import DictBusiness from "@/components/DictBusiness";

//自定义搜索框下拉框组件
import SelectFrame from "@/views/components/SelectFrame";
//下拉表格组件
import selectFrameUp from "@/views/components/selectFrameUp";
//搜索框组件
import searchBox from "@/views/components/searchBox";
//页眉头部组件
import HeadTool from "@/views/components/headTool";
//自定义卡片组件
import ComCard from "@/views/components/com-card";
//阿里图标组件
import iconParkALi from "@/views/components/iconParkALi";
//用到业务字典的地方不多就不需要以下方法
//导入业务字典混入
// import business from '@/utils/mixin/business'
// //注册全局业务字典混入
// Vue.mixin(business)

// 1. 全局挂载
import Print from "vue-print-nb";
import { fcount } from "@/utils";
Vue.use(Print);
// 全局方法挂载
Vue.prototype.getDicts = getDicts;
Vue.prototype.getConfigKey = getConfigKey;
Vue.prototype.parseTime = parseTime;
Vue.prototype.resetForm = resetForm;
Vue.prototype.addDateRange = addDateRange;
Vue.prototype.selectDictLabel = selectDictLabel;
Vue.prototype.selectDictLabels = selectDictLabels;
Vue.prototype.download = download;
Vue.prototype.handleTree = handleTree;
Vue.prototype.$echarts = echarts;
Vue.prototype.$select = selectConfig;
Vue.prototype.$dialog = dialogConfig;
Vue.prototype.$syInputConfig = null;
Vue.prototype.$syInput = (type, v) => {
  const config = store.state.permission.inputConfig;
  const keys = {
    1: "system_BUSINESS_DATA_CONFIG_11", // 数量
    2: "system_BUSINESS_DATA_CONFIG_12", // 单价
    3: "system_BUSINESS_DATA_CONFIG_13", // 金额
  };
  const key = keys[type];
  // const regex = new RegExp(`^([0-9-]\\d*\\.?\\d{0,${config[key] || 8}})?.*$`);
  let value = String(!v && v !== 0 ? "" : v);
  value = value
    .replace(/[^\d.]/g, "")
    .replace(/\.{2,}/g, ".")
    .replace(".", "$#$")
    .replace(/\./g, "")
    .replace("$#$", ".")
    .replace(/^\./g, "")
    .replace(/^0+(?!\.|$)/g, "");
  let part = String(value).match(/\.\d*/);
  part = part?.[0]?.length - 1
  const pNum = config[key] || 0;
  if (part > 0 && part > pNum) {
    value = fcount([value], "", {
      pow: (pNum || Number(pNum) === 0) && pNum >= 0 && pNum <= 8 ? pNum : 8,
      type: "round",
    });
  }
  value = value >= 9999999 ? 9999999 : value;
  return value;
};
// 全局组件挂载
Vue.component("DictTag", DictTag);
Vue.component("DictTag", DictTag);
Vue.component("Pagination", Pagination);
Vue.component("RightToolbar", RightToolbar);
Vue.component("Editor", Editor);
Vue.component("FileUpload", FileUpload);
Vue.component("ImageUpload", ImageUpload);
Vue.component("ImagePreview", ImagePreview);
Vue.component("SelectFrame", SelectFrame);
Vue.component("selectFrameUp", selectFrameUp);
Vue.component("searchBox", searchBox);
Vue.component("HeadTool", HeadTool);
Vue.component("ComCard", ComCard);
Vue.component("DictBusiness", DictBusiness);
//阿里图标组件
Vue.component("iconParkALi", iconParkALi);
/* 全局函数当作插件来注册 */
Vue.use(publicMethods);
Vue.use(directive);
Vue.use(plugins);
Vue.use(VueMeta);
DictData.install();

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 */

Vue.use(Element, {
  size: Cookies.get("size") || "medium", // set element-ui default size
});

Vue.config.productionTip = false;

new Vue({
  el: "#app",
  router,
  store: useStore,
  render: (h) => h(App),
});
