import user from './user'
const webSocket = {
  state: {
    socketTask: null,
    socketUrl: process.env.VUE_APP_SOCKET_API,
    lockReconnect: false, //是否真正建立连接
    timeout: user.state.heartbeat || 5, // 几秒一次心跳
    timeoutObj: null, //心跳心跳倒计时
    serverTimeoutObj: null, //心跳倒计时
    timeoutnum: null, //断开 重连倒计时
    isQuit: false, // 主动退出
    reconnectNum: 0 // 重连次数
  },
  actions: {},
  mutations: {}
}

export default webSocket
