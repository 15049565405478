export default {
  data () {
    return {
      // 添加标志位
      firstTimeActivated: false
    }
  },
  // 这里面组件选项的配置都是可以的,生命周期等,data属性,计算属性,监听属性等
  async created () {
    this.onPageShow()
  },
  activated () {
    if (this.firstTimeActivated) {
      this.onPageShow()
    } else {
      this.firstTimeActivated = true
    }
  }
}
