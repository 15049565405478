
export const SYS_COLORS = [
    '#FFFFFF',
    '#000000',
    '#FF0000',
    '#FF7F00',
    '#FFFF00',
    '#00FF00',
    '#00FFFF',
    '#0000FF',
    '#8B00FF',
]

export const TEXT_ALIGN = [
    { name: "align-text-left", style: "y-start", text: "左对齐" },
    { name: "align-text-center", style: "y-c", text: "居中对齐" },
]

export const TEXT_SIZE = [
    { name: "font-size", fontSize: 16, text: "16px" },
    { name: "font-size", fontSize: 14, text: "14px" },
    { name: "font-size", fontSize: 12, text: "12px" }
]

export const TEXT_BOLD = [
    { name: "font-size", weight: "", text: "常规体" },
    { name: "font-size", weight: "bold", text: "加粗体" }
]

export const MOFANG_TYPE = [
    { style: 1, text: "左一右一" },
    { style: 2, text: "左一右二" }
]
