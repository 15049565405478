//默认有效开始时间为今天
export function getBegDate () {
  var data = new Date()
  var Da = new Date(data.getTime())
  var y = Da.getFullYear()
  var m = Da.getMonth() + 1
  var d = Da.getDate()
  var H = Da.getHours()
  var mm = Da.getMinutes()
  var ss = Da.getSeconds()
  m = m < 10 ? '0' + m : m
  d = d < 10 ? '0' + d : d
  H = H < 10 ? '0' + H : H
  mm = mm < 10 ? '0' + mm : mm
  ss = ss < 10 ? '0' + ss : ss
  return y + '-' + m + '-' + d + ' ' + H + ':' + mm + ':' + ss
}

//默认有效开始时间为十年

export function getEndDate () {
  var data = new Date()
  var Da = new Date(data.getTime() + 24 * 60 * 60 * 1000 * 30)
  var y = Da.getFullYear() + 99
  var m = Da.getMonth()
  var d = Da.getDate()
  var H = Da.getHours()
  var mm = Da.getMinutes()
  var ss = Da.getSeconds()
  m = m < 10 ? '0' + m : m
  d = d < 10 ? '0' + d : d
  H = H < 10 ? '0' + H : H
  mm = mm < 10 ? '0' + mm : mm
  ss = ss < 10 ? '0' + ss : ss
  return y + '-' + m + '-' + d + ' ' + H + ':' + mm + ':' + ss
}
