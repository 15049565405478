import router from './router'
import store from './store'
import { MessageBox, Message } from 'element-ui'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getToken } from '@/utils/auth'
import { isRelogin } from '@/utils/request'
import Cookies from 'js-cookie'

NProgress.configure({ showSpinner: false })

const whiteList = [
  '/user/login',
  '/loginNew',
  '/authorizationBinding',
  '/retrievePW',
  '/auth-redirect',
  '/bind',
  '/register',
  '/console',
  '/createStep'
]

router.beforeEach((to, from, next) => {
  // console.log('跳转路由拦截 == ', to, from, next)
  NProgress.start()
  if (getToken()) {
    to.meta.title && store.dispatch('settings/setTitle', to.meta.title)
    /* has token*/
    if (to.path === '/user/login') {
      next({ path: '/' })
      NProgress.done()
    } else {
      if (store.getters.roles.length === 0) {
        isRelogin.show = true
        // 判断当前用户是否已拉取完user_info信息
        store
          .dispatch('GetInfo')
          .then(() => {
            isRelogin.show = false
            store.dispatch('GenerateRoutes').then(accessRoutes => {
              // console.log('路由表 1= ', accessRoutes)
              if (accessRoutes.length <= 1) {
                return next(`/creation/console`)
              }
              // 根据roles权限生成可访问的路由表
              router.addRoutes(accessRoutes) // 动态添加可访问路由表
              // console.log('路由表 2= ', router)
              next({ ...to, replace: true }) // hack方法 确保addRoutes已完成
              if (
                store.state.user.userinfo?.productServiceTime?.isRequireWarn
              ) {
                MessageBox({
                  title: '提示',
                  message: `您的系统产品将于${store.state.user.userinfo?.productServiceTime
                    ?.serviceExpireTime || ''
                    }到期，为了不影响您的正常使用，请您尽快联系服务商续费！`,
                  type: 'warning',
                  showCancelButton: false, // 不显示取消按钮
                  confirmButtonText: '确定',
                  callback: action => {
                    if (action === 'confirm') {
                      // 用户点击了确定按钮，执行相应的逻辑
                    }
                  }
                })
              }
            })
          })
          .catch(err => {
            store.dispatch('LogOut').then(() => {
              Message.error(err)
              next({ path: '/' })
            })
          })
      } else {
        //动态路由表等于小于1时并且有携带产品id,重新获取动态路由表
        if (
          (store.getters.addRoutes.length <= 1 &&
            store.getters.productId &&
            store.getters.tenantId) ||
          store.getters.addRoutes.length <= 1
        ) {
          store.dispatch('GenerateRoutes').then(accessRoutes => {
            // 根据roles权限生成可访问的路由表
            router.addRoutes(accessRoutes) // 动态添加可访问路由表
            // console.log('路由 21= ', accessRoutes)
            //   console.log('路由表 22= ', router.$options.router)
            next({ ...to, replace: true }) // hack方法 确保addRoutes已完成
          })
          store.dispatch('GetInfo').then(() => { })
        } else {
          // console.log('路由跳转 == ', from.path)
          store.commit('SET_PREVIOUS_ROUTE_PATH', from.path);
          next()
        }
        next()
      }
    }
  } else {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next()
    } else {
      // next(`/login?redirect=${to.fullPath}`) // 否则全部重定向到登录页
      next({ path: '/user/login', params: { redirect: to.fullPath } }) // 否则全部重定向到登录页
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})
