import { basicsIcon } from '@/views/O2OMall/config/option-list/basics-list' // 基础组件
import { personalCenter } from '@/views/O2OMall/config/option-list/personal-list' // 个人中心组件
import { categoryPage } from '@/views/O2OMall/config/option-list/category-list' // 分类组件
import { goodsDetail } from '@/views/O2OMall/config/option-list/goods-detail-list' // 商品详情组件
import { marketingIcon } from '@/views/O2OMall/config/option-list/marketing-list' // 营销组件
console.log('basicsIconbasicsIconbasicsIcon', basicsIcon);
const template = {
  state: {
    selectIndex: 0, // 模板下表
    tempData: [], // 模板列表（包含详细的组件参数和数据信息）
    fixationTempData: [], // 绝对定位的组件列表
    labelList: [], // 模板标签列表
    rightData: {}, // 右侧编辑数据
    pageName: '', // 模板标签页
    // 使用数据
    basicsIconList: [],
    marketingIconList: [],
    goodsDetailList: [],
    categoryPageList: [],
    personalCenterList: [],
    // 基础数据
    // 基础组件
    basicsIcon,
    // 营销组件
    marketingIcon,
    //商品详情组件
    goodsDetail,
    //分类组件
    categoryPage,
    //个人中心组件
    personalCenter
  },
  actions: {
    setTemplateTempData({ commit }, data) {
      commit('SET_TEMPLATE_TEMPDATA', data)
    },
    setTemplateRightData({ commit }, data) {
      // console.log('setTemplateRightData', data)
      commit('SET_TEMPLATE_RIGHTDATA', data)
    },
    changeIndex({ commit }, data) {
      commit('CHANGE_INSET_INDEX', data)
    }
  },
  mutations: {
    SET_TEMPLATE_TEMPDATA(state, data) {
      state.tempData = data
    },
    SET_TEMPLATE_RIGHTDATA(state, data) {
      state.rightData = data
    },
    CHANGE_INSET_INDEX(state, index) {
      console.log('CHANGE_INSET_INDEX', index)
      state.selectIndex = index
    },
    DELETE_UPDATA_NUM(state, name) {
      state.basicsIconList.forEach(item => {
        if (item.name == name) {
          item.num--
          if (item.num < 0) item.num == 0
        }
      })
      state.marketingIconList.forEach(item => {
        if (item.name == name) {
          item.num--
          if (item.num < 0) item.num == 0
        }
      })
      state.goodsDetailList.forEach(item => {
        if (item.name == name) {
          item.num--
          if (item.num < 0) item.num == 0
        }
      })
      state.categoryPageList.forEach(item => {
        if (item.name == name) {
          item.num--
          if (item.num < 0) item.num == 0
        }
      })
      state.personalCenterList.forEach(item => {
        if (item.name == name) {
          item.num--
          if (item.num < 0) item.num == 0
        }
      })
    }
  }
}
export default template
