import { deepCopy } from '@/utils'

//表格下拉框
export function getTableSelect(optionGoods, that, row) {
  return that.$select({
    key: optionGoods.key || 'listGoods',
    option: {
      option: {
        remoteBody: { goodsTypes: optionGoods.goodsTypes || [0, 1, 2, 3, 4] },
        showItem: [
          {
            [optionGoods.rowId || 'goodsId']: optionGoods?.row?.goodsId || '',
            [optionGoods.rowName || 'goodsNo']: optionGoods?.row?.goodsNo || ''
          }
        ],
        change: (propValue, row) => {
          //调用赋值方法  下拉
          if (optionGoods?.forbidden) {
            that.$message.warning(optionGoods?.forbiddenTitle || '')
            optionGoods.getMultipleGoods([])
            return
          }
          if (propValue) {
            const findIndex = optionGoods.billDetailItems.findIndex(
              item => item.goodsNo == propValue
            )
            let rows = JSON.parse(JSON.stringify(row))
            rows.findIndex = findIndex
            optionGoods.getMultipleGoods([rows])
          } else {
            that.$nextTick(() => {
              //   console.log('11')
            })
          }
        },
        tableChange: (propValue, row) => {
          //调用赋值方法  表格
          if (optionGoods?.forbidden) {
            return that.$message.warning(optionGoods?.forbiddenTitle || '')
          }
          optionGoods.getMultipleGoods(row.check)
        },
        buttons: [
          {
            type: 'more',
            option: {
              title: optionGoods.dialogTitle || '选择商品',
              width: optionGoods.dialogWidth || 1250,
              type: 'TreeAndTable',
              formData: that.$dialog({
                key: optionGoods.dialogFormDataKey || 'goods',
                option: {
                  // treeApi: undefined,
                  queryParams: {
                    goodsTypes: optionGoods.goodsTypes || [0, 1, 2, 3, 4]
                  },
                  table: {
                    ...that.$dialog({
                      key: optionGoods.dialogTableKey || 'goods'
                    }).table
                  }
                }
              })
            }
          }
        ]
      }
    }
  }).option
}
//利用选择的商品进行表格赋值
export function getSelectAssign(rows, table) {
  let findIndex = rows[0]?.findIndex
  if (rows.length == 1 && findIndex != undefined) {
    table[findIndex] = rows[0]
  } else {
    table.push(...rows)
  }
  table = table
    .filter(
      item => item.goodsId && item.goodsNo != '' && item.goodsNo != undefined
    )
    .map(x => ({ ...x, autoShowTdId: `${x.goodsId}.${x.unitId}` }))
  return table
}
//表格行hover时,显示操作加减号
export function getCellMouseEnter(row, table) {
  let eleIndex = table.indexOf(row)
  if (eleIndex != -1) {
    table[eleIndex].hoverRow = true
  }
  return table
}
//表格行离开hover时,不显示操作加减号,显示序号
export function getCellMouseLeave(table) {
  for (let index = 0; index < table.length; index++) {
    const element = table[index]
    if (element['hoverRow']) {
      element['hoverRow'] = false
    }
  }
  return table
}
