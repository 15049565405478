import { fcount } from "@/utils";

//基本单位售价
export function unitSalePriceChange (value, row) {
  row.units.forEach(v => {
    if (v.unitId === value) {
      if (['140310', '140311'].includes(this.billType)) {
        this.$set(row, 'unitPurPrice', v.purPrice)
        const unitPurMoney = row.unitQty * v.purPrice
        this.$set(row, 'unitPurMoney', unitPurMoney)
        this.$set(row, 'unitSalePrice', v.salePrice)
        const unitSaleMoney = $syInput(3, fcount([row.unitQty, v.salePrice], '*')) 
        this.$set(row, 'unitSaleMoney', unitSaleMoney)
        return
      }
      this.$set(row, 'unitPrice', v.salePrice)
      const unitMoney = row.unitQty * row.unitPrice
      this.$set(row, 'unitMoney', unitMoney)
      this.$set(row, 'goodsSpec', v.goodsSpec)
    }
  })
}
//基本单位进价
export function unitPriceChange (value, row) {
  row.units.forEach(v => {
    if (v.unitId === value) {
      this.$set(row, 'unitPrice', v.unitPrice)
      const unitMoney = row.unitQty * row.unitPrice
      this.$set(row, 'unitMoney', unitMoney)
    }
  })
}

//仓库基本单位售价
export function checkUnitSalePriceChange (value, row) {
  row.units.forEach(v => {
    if (v.unitId === value) {
      this.$set(row, 'unitSalePrice', v.salePrice)
      const unitMoney = row.unitQty * row.unitSalePrice
      this.$set(row, 'unitMoney', unitMoney)
    }
  })
}
