const CryptoJS = require("crypto-js");


/**
 * 加密
 * @param {*} message  加密信息
 * @param {*} key  加密key
 */
export function encryptAes(message, key) {
  var secretKey = CryptoJS.enc.Utf8.parse(key);//key  为你的秘钥
  var srcs = CryptoJS.enc.Utf8.parse(message);
  var encrypted = CryptoJS.AES.encrypt(srcs, secretKey, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
  console.log('username', encrypted.toString())
  return encrypted.toString();
}

/**
 * 解密
 * @param {*} message  解密信息
 * @param {*} key  解密key
 */
export function decryptAes(message, key) {
  var secretKey = CryptoJS.enc.Utf8.parse(key);
  var decrypt = CryptoJS.AES.decrypt(message, secretKey, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
  return CryptoJS.enc.Utf8.stringify(decrypt).toString();
}
