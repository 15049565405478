//公用的方法
import Vue from 'vue'
import { getBillNo } from '@/api/codeRule'

//input 显示时保留n位小数,四舍五入 输入时显示实际值
//引用类型 使用对象/数组
/* num 保留的小数位数
   type 类型 
   e     $event
   showData 显示的对象/数组
   savaData 实际的对象/数组
   value  属性名
   index 下标
    */
export function showFormatting(
  num,
  type,
  e,
  showData,
  savaData,
  value,
  index,
  newVal = undefined
) {
  if (type === 'blur') {
    //change事件
    if (e.target.value && !isNaN(e.target.value)) {
      Vue.set(savaData[index], value, parseFloat(e.target.value) || 0)
      Vue.set(showData[index], value, savaData[index][value].toFixed(num))
      if (showData[index][value] === '')
        Vue.set(saveData[index], value, showData[index][value])
    } else {
      Vue.set(savaData[index], value, undefined)
    }
  } else if (type === 'focus') {
    if (showData[index][value] === '') {
      Vue.set(savaData[index], value, showData[index][value])
      return
    }
    console.log('focus')
    if (savaData[index][value]) {
      Vue.set(showData[index], value, savaData[index][value].toString()) //focus事件
    }
  } else {
    if (newVal) {
      Vue.set(showData[index], newVal, Number(e).toFixed(num))
      Vue.set(savaData[index], newVal, Number(e))
      return
    }
    if (Object.keys(showData[index]).length) {
      Vue.set(showData[index], value, Number(savaData[index][value]).toFixed(num))
    } else {
      showData[index] = {}
      Vue.set(showData[index], value, Number(savaData[index][value]).toFixed(num))
    }

  }
}

//获取单据编号
export function getNewBillNo(obj, val, type) {
  return new Promise(resolve => {
    getBillNo(type).then(response => {
      Vue.set(obj, val, response)
      resolve(response)
    })
  })
}
