import { getBusiness } from '@/api/system/business/data'
const business = {
  data () {
    return {
      busiDicts: {}
    }
  },
  methods: {
    async business (msg) {
      const res = await getBusiness(msg)
      this.$set(this.busiDicts, msg, res.data)
    }
  }
}
export default business
