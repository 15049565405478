import auth from "@/plugins/auth";
import router, { constantRoutes, dynamicRoutes } from "@/router";
import { getRouters } from "@/api/menu";
import Layout from "@/layout/index";
import ParentView from "@/components/ParentView";
import InnerLink from "@/layout/components/InnerLink";
import { deepClone, routerPathToName } from "@/utils";
import { dataListAPI } from "@/api/system/maintain/param/dataParam";

const permission = {
  state: {
    previousRoutePath: '',
    inputConfig: {},
    routes: [],
    addRoutes: [],
    defaultRoutes: [],
    topbarRouters: [],
    sidebarRouters: [],
    selectRouterList: [],
  },
  mutations: {
    SET_PREVIOUS_ROUTE_PATH(state, path) {
      state.previousRoutePath = path;
    },
    SET_ROUTES: (state, routes) => {
      state.addRoutes = routes;
      state.routes = constantRoutes.concat(routes);
    },
    SET_SY_INPUT_CONFIG: (state, vlaue) => {
      state.inputConfig = vlaue;
    },
    SET_DEFAULT_ROUTES: (state, routes) => {
      state.defaultRoutes = constantRoutes.concat(routes);
    },
    SET_TOPBAR_ROUTES: (state, routes) => {
      state.topbarRouters = routes;
      routes.forEach((item) => {
        if (item.children) {
          item.children.forEach((v) => {
            if (v.children) {
              v.children.forEach((j) => {
                state.selectRouterList.push({
                  mainMenuName: item.meta.title,
                  submenuName: j.meta.title,
                  routerPath: j.routerPath,
                });
              });
            }
          });
        }
      });
    },
    SET_SIDEBAR_ROUTERS: (state, routes) => {
      state.sidebarRouters = routes;
    },
    RESET_ROUTES: (state) => {
      state.routes = [
        ...constantRoutes,
        { path: "*", redirect: "/404", hidden: true },
      ];
      state.addRoutes = [{ path: "*", redirect: "/404", hidden: true }];
      state.defaultRoutes = constantRoutes;
      (state.topbarRouters = []),
        (state.selectRouterList = []),
        (state.sidebarRouters = constantRoutes);
    },
  },
  actions: {
    // 生成路由
    GenerateRoutes({ commit }, obj) {
      return new Promise(async (resolve) => {
        // 向后端请求路由数据
        try {
          const res = await dataListAPI();
          commit("SET_SY_INPUT_CONFIG", res?.data || {});
        } catch (error) { }
        getRouters(obj).then((res) => {
          const sdata = JSON.parse(JSON.stringify(res.data));
          const rdata = JSON.parse(JSON.stringify(res.data));
          const sidebarRoutes = filterAsyncRouter(sdata);
          const rewriteRoutes = filterAsyncRouter(rdata, false, true);
          const asyncRoutes = filterDynamicRoutes(dynamicRoutes);
          rewriteRoutes.push({ path: "*", redirect: "/404", hidden: true });
          router.addRoutes(asyncRoutes);
          commit("SET_ROUTES", rewriteRoutes);
          commit("SET_SIDEBAR_ROUTERS", constantRoutes.concat(sidebarRoutes));
          commit("SET_DEFAULT_ROUTES", sidebarRoutes);
          commit("SET_TOPBAR_ROUTES", sidebarRoutes);
          resolve(rewriteRoutes);
        });
      });
    },
  },
};

// 遍历后台传来的路由字符串，转换为组件对象
function filterAsyncRouter(asyncRouterMap, lastRouter = false, type = false) {
  return asyncRouterMap.map((x) => {
    const route = deepClone(x);
    if (type && route.children) {
      route.children = filterChildren(route.children);
    }
    if (route.component) {
      // Layout ParentView 组件特殊处理
      if (route.component === "Layout") {
        route.component = Layout;
      } else if (route.component === "ParentView") {
        route.component = ParentView;
      } else if (route.component === "InnerLink") {
        route.component = InnerLink;
      } else {
        route.component = async () => {
          const cpn = require(`@/views/${String(x.component)}`);
          cpn.default.name = routerPathToName(x.routerPath);
          return cpn;
        };
      }
    }
    if (route.children != null && route.children && route.children.length) {
      route.children = filterAsyncRouter(route.children, route, type);
    } else {
      delete route["children"];
      delete route["redirect"];
    }
    return route;
  });
}

function filterChildren(childrenMap, lastRouter = false) {
  var children = [];
  childrenMap.forEach((el, index) => {
    if (el.children && el.children.length) {
      if (el.component === "ParentView" && !lastRouter) {
        el.children.forEach((c) => {
          c.path = el.path + "/" + c.path;
          if (c.children && c.children.length) {
            children = children.concat(filterChildren(c.children, c));
            return;
          }
          children.push(c);
        });
        return;
      }
    }
    if (lastRouter) {
      el.path = lastRouter.path + "/" + el.path;
    }
    children = children.concat(el);
  });
  return children;
}

// 动态路由遍历，验证是否具备权限
export function filterDynamicRoutes(routes) {
  const res = [];
  routes.forEach((route) => {
    if (route.permissions) {
      if (auth.hasPermiOr(route.permissions)) {
        res.push(route);
      }
    } else if (route.roles) {
      if (auth.hasRoleOr(route.roles)) {
        res.push(route);
      }
    }
  });
  return res;
}

export const loadView = (view) => {
  if (process.env.NODE_ENV === "development") {
    return (resolve) => require([`@/views/${view}`], resolve);
  } else {
    // 使用 import 实现生产环境的路由懒加载
    return (resolve) => require([`@/views/${view}`], resolve);
  }
};

export default permission;
