const getters = {
  sidebar: state => state.app.sidebar,
  size: state => state.app.size,
  device: state => state.app.device,
  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  userinfo: state => state.user.userinfo,
  introduction: state => state.user.introduction,
  roles: state => state.user.roles,
  permissions: state => state.user.permissions,
  permission_routes: state => state.permission.routes,
  topbarRouters: state => state.permission.topbarRouters,
  selectRouterList: state => state.permission.selectRouterList,
  defaultRoutes: state => state.permission.defaultRoutes,
  sidebarRouters: state => state.permission.sidebarRouters,
  ProductDetail: state => state.product.ProductDetail,
  addRoutes: state => state.permission.addRoutes,
  productId: state => state.user.productId,
  tenantId: state => state.user.tenantId,
  curTenant: state => state.user.curTenant,
  previousRoutePath: state => state.permission.previousRoutePath
}
export default getters
