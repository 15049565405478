// 获取当前时间的年月日函数
export function getNewDate (num) {
  // num 传数字 当前日期天数自加 不传就是当前日期
  if (num) {
    let today = new Date(); // 当前日期和时间
    let data = new Date(today.getTime() + 24 * 60 * 60 * (num * 1000)); // 获取后面的天数
    let theYear = data.getFullYear();
    let theMonth = data.getMonth() + 1; 
    let theDay = data.getDate();
    let billDate =  `${theYear}-${theMonth > 9? theMonth : '0' + theMonth}-${theDay > 9? theDay : '0' + theDay}`
    return billDate
  } else {
    let myDate = new Date()
    let myYear = myDate.getFullYear() //获取完整的年份(4位,1970-????)
    let myMonth = myDate.getMonth() + 1 //获取当前月份(0-11,0代表1月)
    let myToday = myDate.getDate() //获取当前日(1-31)
    myMonth = myMonth > 9 ? myMonth : '0' + myMonth
    myToday = myToday > 9 ? myToday : '0' + myToday
    const billDate = `${myYear}-${myMonth}-${myToday}`
    return billDate
  }
}
// 获取当前时间的年月日时分秒函数
export function getTimeDate () {
  const sign = ':'
  let myDate = new Date()
  let myYear = myDate.getFullYear() //获取完整的年份(4位,1970-????)
  let myMonth = myDate.getMonth() + 1 //获取当前月份(0-11,0代表1月)
  let myToday = myDate.getDate() //获取当前日(1-31)
  let hour = myDate.getHours() // 时
  let minutes = myDate.getMinutes() // 分
  let seconds = myDate.getSeconds() //秒
  myMonth = myMonth > 9 ? myMonth : '0' + myMonth
  myToday = myToday > 9 ? myToday : '0' + myToday

  if (hour >= 0 && hour <= 9) {
    hour = '0' + hour
  }
  if (minutes >= 0 && minutes <= 9) {
    minutes = '0' + minutes
  }
  if (seconds >= 0 && seconds <= 9) {
    seconds = '0' + seconds
  }
  return (
    myYear +
    '-' +
    myMonth +
    '-' +
    myToday +
    ' ' +
    hour +
    sign +
    minutes +
    sign +
    seconds
  )
}
// 获取上一年时间的年月日时分秒函数
export function getLastYearTimeDate () {
  const sign = ':'
  let myDate = new Date()
  let myYear = myDate.getFullYear() - 1 //获取完整的年份(4位,1970-????)
  let myMonth = myDate.getMonth() + 1 //获取当前月份(0-11,0代表1月)
  let myToday = myDate.getDate() //获取当前日(1-31)
  let hour = myDate.getHours() // 时
  let minutes = myDate.getMinutes() // 分
  let seconds = myDate.getSeconds() //秒
  myMonth = myMonth > 9 ? myMonth : '0' + myMonth
  myToday = myToday > 9 ? myToday : '0' + myToday

  if (hour >= 0 && hour <= 9) {
    hour = '0' + hour
  }
  if (minutes >= 0 && minutes <= 9) {
    minutes = '0' + minutes
  }
  if (seconds >= 0 && seconds <= 9) {
    seconds = '0' + seconds
  }
  return (
    myYear +
    '-' +
    myMonth +
    '-' +
    myToday +
    ' ' +
    hour +
    sign +
    minutes +
    sign +
    seconds
  )
}
// 获取今天日期时间  带时分秒
export function getNewToday () {
  //声明时间范围的数组
  let arr = []
  // 获取当前日期时间
  let now = new Date()
  // 获取今天日期  开始
  let today =
    now.getFullYear() +
    '-' +
    (now.getMonth() + 1) +
    '-' +
    now.getDate() +
    ' 00:00:00'
  // 获取今天日期  结束
  let todayLast =
    now.getFullYear() +
    '-' +
    (now.getMonth() + 1) +
    '-' +
    now.getDate() +
    ' 23:59:59'
  arr = [today, todayLast]
  return arr
}
// 获取昨天日期时间  带时分秒
export function getNewYesterday () {
  //声明时间范围的数组
  let arr = []
  // 获取当前日期时间
  let now = new Date()
  // 获取昨天日期  开始
  let yesterday = new Date(now.setDate(now.getDate() - 1))
  yesterday =
    yesterday.getFullYear() +
    '-' +
    (yesterday.getMonth() + 1) +
    '-' +
    yesterday.getDate() +
    ' 00:00:00'
  // 获取昨天日期    结束
  let yesterdayLast = new Date(now.setDate(now.getDate() - 1))
  yesterdayLast =
    yesterdayLast.getFullYear() +
    '-' +
    (yesterdayLast.getMonth() + 1) +
    '-' +
    (yesterdayLast.getDate() + 1) +
    ' 23:59:59'

  arr = [yesterday, yesterdayLast]
  return arr
}
// 获取本周日期时间  带时分秒
export function getNewWeekStart () {
  //声明时间范围的数组
  let arr = []
  // 获取当前日期时间
  let now = new Date()
  // 获取本周开始日期     开始
  let weekStart = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() - now.getDay() + 1
  )
  weekStart =
    weekStart.getFullYear() +
    '-' +
    (weekStart.getMonth() + 1) +
    '-' +
    weekStart.getDate() +
    ' 00:00:00'

  // 获取本周开始日期     结束
  let weekStartLast = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() - now.getDay() + 7
  )
  weekStartLast =
    weekStartLast.getFullYear() +
    '-' +
    (weekStartLast.getMonth() + 1) +
    '-' +
    weekStartLast.getDate() +
    ' 23:59:59'
  arr = [weekStart, weekStartLast]
  return arr
}


/* 
printDateType : 样式类型  (1:2018年07月12日  2:2018/07/12  3:yyyy年dd月tt日 HH时mm分ss秒  4:2018/07/12 10:24:56)
formatType : 默认类型 '/'  可传不同符号分割 
addYear: 当前年份(增加/减少)N年
addMonth: 当前月份(增加/减少)N月
addDay: 当前天数(增加/减少)N天
 */
export function getFormattedDate (
  printDateType,
  formatType = '/',
  addYear = 0,
  addMonth = 0,
  addDay = 0
) {
  const currentDate = new Date()
  currentDate.setDate(currentDate.getDate() + addDay)
  const year = currentDate.getFullYear() + addYear
  const month = String(currentDate.getMonth() + 1).padStart(2, '0')
  const day = String(currentDate.getDate()).padStart(2, '0')
  const hours = String(currentDate.getHours()).padStart(2, '0')
  const minutes = String(currentDate.getMinutes()).padStart(2, '0')
  const seconds = String(currentDate.getSeconds()).padStart(2, '0')
  let billDate = ''
  switch (printDateType) {
    case 1:
      return `${year}年${month}月${day}日`

    case 2:
      if (formatType) {
        billDate = `${year}${formatType}${month}${formatType}${day}`
      } else {
        billDate = `${year}${month}${day}`
      }
      return billDate
    case 3:
      return `${year}年${month}月${day}日 ${hours}时${minutes}分${seconds}秒`

    case 4:
      if (formatType) {
        billDate = `${year}${formatType}${month}${formatType}${day} ${hours}:${minutes}:${seconds}`
      } else {
        billDate = `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`
      }
      return billDate

    case 5:
      billDate = `${hours}:${minutes}:${seconds}`
      return billDate

    case 6:
      billDate = `${year}${month}${day}${hours}${minutes}${seconds}`
      return billDate
    default:
      return currentDate.toLocaleDateString()
  }
}